import React, {
  createContext,
  useContext,
  Context,
  Consumer,
  ReactNode,
  useMemo,
} from 'react';

import dataMainnet from '../contracts_mainnet.json';
import dataLocalterra from '../contracts.json';

export interface ContractsProviderProps {
  children: ReactNode;
}

export interface Contracts {
  slotsAddr?: string;
}

// @ts-ignore
const ContractsContext: Context<Contracts> = createContext<Contracts>();

export function ContractsProvider({ children }: ContractsProviderProps) {
  const slotsAddr = useMemo(() => {
    const data =
      process.env.REACT_APP_CHAIN === 'mainnet' ? dataMainnet : dataLocalterra;

    return data?.find(({ name }: { name: string }) => name === 'slots')
      ?.contractAddress;
  }, []);

  const states = useMemo<Contracts>(() => {
    return {
      slotsAddr,
    };
  }, [slotsAddr]);

  return (
    <ContractsContext.Provider value={states}>
      {children}
    </ContractsContext.Provider>
  );
}

export function useContracts(): Contracts {
  return useContext(ContractsContext);
}

export const ContractsConsumer: Consumer<Contracts> = ContractsContext.Consumer;
