import { useWallet, WalletStatus } from '@terra-money/wallet-provider';

import useAnalyticsEventTracker from 'hooks/useAnalyticsEventTracker';
import './SlotMachine/spinner.css';

export default function Disconnect() {
  const { status, disconnect } = useWallet();

  const gaEventTracker = useAnalyticsEventTracker();

  const disconnectBtnOnClick = () => {
    gaEventTracker('Wallet', 'Disconnect', 'Disconnect button click', 0);
    disconnect();
  };

  return (
    <div>
      {status === WalletStatus.WALLET_CONNECTED && (
        <button
          className="spin-btn"
          style={{
            width: '100px',
            height: '30px',
            fontSize: '1em',
            textAlign: 'right',
          }}
          onClick={disconnectBtnOnClick}
        >
          Disconnect
        </button>
      )}
    </div>
  );
}
