import styled from 'styled-components';
import Button from './Button';
import Modal from './Modal';

const ModalContent = styled.div`
  width: 100%;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.35);
  background-color: #fff;
  padding: 30px 0px;
  color: #5c5c5c;
  & > div {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 80vh;
    overflow-y: auto;
    padding: 0 30px;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #5c5c5c;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
    padding: 30px 0px;
    & > div {
      padding: 0 16px;
    }
  }
`;

const ModalTitle = styled.div`
  display: block;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #0222ba;
  margin-bottom: 30px;
`;

const Box = styled.div`
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  font-weight: 700;
`;

const HelpModal: React.FC<{ isOpen?: boolean; handleClose: () => void }> = ({
  isOpen = false,
  handleClose,
}) => {
  // const network = useNetwork();
  // const chainOptions = useChainOptions();
  // const availableNetworks = useMemo(() => {
  //   if (chainOptions?.walletConnectChainIds) {
  //     const keys = Object.keys(chainOptions?.walletConnectChainIds).map(Number);
  //     return keys
  //       .filter((key) =>
  //         AVAILABLE_CHAIN_ID_LIST.includes(
  //           chainOptions?.walletConnectChainIds[key]?.chainID,
  //         ),
  //       )
  //       .map((key) => chainOptions?.walletConnectChainIds[key]);
  //   }
  //   return [];
  // }, [chainOptions]);

  return (
    <Modal
      isOpen={isOpen}
      close={() => {
        handleClose();
      }}
      open={() => {}}
    >
      <ModalContent>
        <div>
          <ModalTitle>Game Instructions</ModalTitle>
          <div style={{ marginBottom: 20 }}>
            Connect your Terra Station wallet to this app, adjust your bet size
            and press the <b>SPIN</b> button!
            <br />
            <br />
            You win the round when you get multiple consecutive repeated
            symbols, your winnings multiplier is shown in the table below.{' '}
            <b>30% of your winnings will be taxed</b>, of which{' '}
            <b>70% of the tax is burnt</b> and the remaining goes back to the
            jackpot.
            <br />
            <br />
            The max bet size is typically limited to 20% of the current jackpot.
            <br />
            <br />
            <Box>2 Consecutive Symbols: 1.2X Winnings</Box>
            <Box>3 Consecutive Symbols: 2.5X Winnings</Box>
            <Box>4 Consecutive Symbols: 4X Winnings</Box>
            <Box>5 Consecutive Symbols: 5X Winnings</Box>
            <Box>6 Consecutive Symbols: 420X Winnings</Box>
          </div>
          <Button
            size="lg"
            onClick={() => handleClose()}
            style={{
              textTransform: 'unset',
              maxWidth: 235,
              borderRadius: 10,
              marginBottom: 4,
            }}
          >
            I feel lucky now
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default HelpModal;
