import {
  Fee,
  LCDClient,
  MsgExecuteContract,
  TxInfo,
} from '@terra-money/terra.js';
import {
  ConnectType,
  NetworkInfo,
  useConnectedWallet,
  useWallet,
  WalletStatus,
} from '@terra-money/wallet-provider';
import { useContracts } from 'contexts/contracts';
import { slots } from 'contract';
import { TX_KEYS, TX_REFETCH_MAP } from 'env';
import { QUERY_KEYS } from 'env';

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useQueryClient } from 'react-query';
import { useQuery } from 'react-query';

import styled from 'styled-components';

import CountUp from 'react-countup';
import ReactGA from 'react-ga';

import Summary, { queryGetStateFn } from '../Summary';
import Spinner from './spinner.js';
import './spinner.css';
import { useAnalyticsEventTracker } from 'hooks';

interface iPrizeText {
  foobar: boolean;
}

// https://codepen.io/mandymichael/pen/xpLNeV
const PrizeText = styled.div<iPrizeText>`
  background: linear-gradient(
    to bottom,
    #cfc09f 22%,
    #634f2c 24%,
    #cfc09f 26%,
    #cfc09f 27%,
    #ffecb3 40%,
    #3a2c0f 78%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-family: 'Playfair Display', serif;
  position: relative;
  text-transform: uppercase;
  font-size: 2vw;
  margin: 0;
  margin-top: -100px;
  font-weight: 400;
  text-align: center;

  visibility: ${(props) => (props.foobar ? 'visible' : 'hidden')};

  &:after {
    background: none;
    content: attr(data-heading);
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    text-shadow: -1px 0 1px #c6bb9f, 0 1px 1px #c6bb9f,
      5px 5px 10px rgba(0, 0, 0, 0.4), -5px -5px 10px rgba(0, 0, 0, 0.4);
  }
`;

const SlotMachineComponent = () => {
  const { status, connect } = useWallet();
  const [betsize, setBetsize] = useState<number>(10); // in LUNA

  // UI
  const [countUpDuration, setCountUpDuration] = useState<number>(0);

  // Jackpot
  const [matches, setMatches] = useState<number[]>([]);
  const [prizeAmount, setPrizeAmount] = useState<number>(0);

  // Web3
  const connectedWallet = useConnectedWallet();
  const queryClient = useQueryClient();
  const { slotsAddr } = useContracts();
  const [isTxLoading, setIsTxLoading] = useState<boolean>(false);

  // Query contract: getState
  const { data } = useQuery(
    [QUERY_KEYS.GET_STATE, connectedWallet?.network, slotsAddr],
    queryGetStateFn,
    {
      refetchInterval: 1000 * 5,
      keepPreviousData: true,
    },
  );

  // Analytics
  const gaEventTracker = useAnalyticsEventTracker();

  useEffect(() => {
    if (connectedWallet?.terraAddress) {
      ReactGA.set({ address: connectedWallet?.terraAddress });
      gaEventTracker('Wallet', 'Connect', connectedWallet?.terraAddress, 0);
      console.log('Wallet:', connectedWallet?.terraAddress);
    }
  }, [connectedWallet?.terraAddress, gaEventTracker]);

  const node1 = useRef<Spinner | null>(null);
  const node2 = useRef<Spinner | null>(null);
  const node3 = useRef<Spinner | null>(null);
  const node4 = useRef<Spinner | null>(null);
  const node5 = useRef<Spinner | null>(null);
  const node6 = useRef<Spinner | null>(null);

  const doSpin = async () => {
    console.log('Spinning...');

    gaEventTracker('Jackpot', 'Spin', 'Jackpot spin', betsize);

    let outcome: number[] = [];

    // Spinner spins indefinitely
    startSpinning();

    // Send tx to chain and wait for tx confirmation
    let txinfo = await postSpinTx();

    // Parse the game results from TxInfo
    if (txinfo?.logs && txinfo?.logs.length) {
      const wasm_events = txinfo.logs[0].events.filter(
        (o) => o.type === 'wasm',
      );
      if (wasm_events) {
        // is_winner
        const is_winner_attr = wasm_events[0].attributes.filter(
          (attr) => attr.key === 'is_winner',
        );
        if (is_winner_attr) {
          const is_winner = is_winner_attr[0].value;
          console.log('Winner?:', is_winner);
        }

        // outcome
        const outcome_attr = wasm_events[0].attributes.filter(
          (attr) => attr.key === 'outcome',
        );
        if (outcome_attr) {
          const outcome_str = outcome_attr[0].value;
          outcome = outcome_str.split(',').map((s) => parseInt(s));
          console.log('Outcome:', outcome);
        }

        // max_count
        const max_count_attr = wasm_events[0].attributes.filter(
          (attr) => attr.key === 'max_count',
        );
        if (max_count_attr) {
          const max_count = max_count_attr[0].value;
          console.log('max_count:', max_count);
        }

        // prize amount
        const prize_amount_attr = wasm_events[0].attributes.filter(
          (attr) => attr.key === 'prize_amount',
        );
        if (prize_amount_attr) {
          let prize_amount = parseInt(prize_amount_attr[0].value) / 10 ** 6;
          console.log('Prize:', prize_amount);

          if (prize_amount > 0) {
            gaEventTracker('Jackpot', 'Win', 'Jackpot win', prize_amount);

            setPrizeAmount(prize_amount);
            const duration = calcCountUpDuration(prize_amount);
            setCountUpDuration(duration);
            setTimeout(() => {
              setPrizeAmount(0); // remove the prize text
            }, (5 + duration) * 1000);
          }
        }
      }
    } else {
      console.error(`Possible error with transaction. ${txinfo?.raw_log}`);
    }

    // Spinner spins to display outcome
    spinToOutcome(outcome);
  };

  const startSpinning = () => {
    node1?.current?.spinForever();
    node2?.current?.spinForever();
    node3?.current?.spinForever();
    node4?.current?.spinForever();
    node5?.current?.spinForever();
    node6?.current?.spinForever();
  };

  const spinToOutcome = (outcome: number[]) => {
    node1?.current?.spinToPosition(outcome[0]);
    node2?.current?.spinToPosition(outcome[1]);
    node3?.current?.spinToPosition(outcome[2]);
    node4?.current?.spinToPosition(outcome[3]);
    node5?.current?.spinToPosition(outcome[4]);
    node6?.current?.spinToPosition(outcome[5]);
  };

  const postTx = useCallback(
    async <T extends {}>(txKey: TX_KEYS, executeMsg: T) => {
      if (!connectedWallet || !slotsAddr) {
        return;
      }

      // Query node for gasPrice
      const resp = await fetch(
        'https://columbus-fcd.terra.dev/v1/txs/gas_prices',
      );
      const gasPrices = await resp.json();
      const gasPrice =
        parseFloat(gasPrices['uluna'].replace('uluna', '')) * 1000000;

      try {
        const { result } = await connectedWallet.post({
          msgs: [
            new MsgExecuteContract(
              connectedWallet.terraAddress,
              slotsAddr,
              executeMsg,
              { uluna: betsize * 1000000 },
            ),
          ],
          fee: new Fee(200000, `${gasPrice}uluna`),
        });
        console.log(`Tx ${result.txhash} sent`);

        const pollResult = await pollTxInfo(
          connectedWallet.network,
          result.txhash,
        );
        console.log(`Tx ${result.txhash} confirmed`);

        if (pollResult.logs && pollResult.logs?.length > 0) {
          await Promise.all(
            TX_REFETCH_MAP[txKey].map((queryKey) => {
              return queryClient.invalidateQueries(queryKey, {
                refetchActive: true,
                refetchInactive: false,
              });
            }),
          );
        }

        return pollResult;
      } catch (error) {
        console.error(error);
      }
    },
    [connectedWallet, slotsAddr, queryClient, betsize],
  );

  const postSpinTx = useCallback(async () => {
    setIsTxLoading(true);
    const txinfo = await postTx<slots.ExecuteMsg.Play>(TX_KEYS.PLAY, {
      play: {},
    });
    setIsTxLoading(false);
    return txinfo;
  }, [postTx]);

  const finishHandler = (value: number) => {
    setMatches([...matches, value]);

    // Wait for all spinners to finish
    if (matches.length === 6) {
      console.log('Finished spinning');
    }
  };

  const calcCountUpDuration = (prizeAmount: number): number => {
    return 1 + Math.log10(prizeAmount);
  };

  const onChangeBetsize = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userBetsize = Math.floor(Number(e.target.value)); // LUNA
    let maxAllowedBetsize = Math.floor(data.pot / 5 / 1000000); // LUNA
    const MIN_BET_SIZE = 10;
    if (maxAllowedBetsize < MIN_BET_SIZE) {
      maxAllowedBetsize = MIN_BET_SIZE;
    }
    const safeBetsize = Math.min(userBetsize, maxAllowedBetsize);
    setBetsize(safeBetsize);
  };

  return (
    <div className="slotmachine-container">
      <Summary betsize={betsize} onChangeBetsize={onChangeBetsize} />
      <div className={`spinner-container`}>
        <Spinner onFinish={finishHandler} ref={node1} timer="1000" />
        <Spinner onFinish={finishHandler} ref={node2} timer="1200" />
        <Spinner onFinish={finishHandler} ref={node3} timer="1500" />
        <Spinner onFinish={finishHandler} ref={node4} timer="1900" />
        <Spinner onFinish={finishHandler} ref={node5} timer="2400" />
        <Spinner onFinish={finishHandler} ref={node6} timer="3000" />
        <div className="gradient-fade"></div>
      </div>

      <CountUp
        start={0}
        end={prizeAmount}
        decimals={6}
        duration={countUpDuration} // 1 sec for each power of 10
        separator=","
        delay={0}
      >
        {({ countUpRef }) => (
          <PrizeText foobar={prizeAmount !== 0}>
            Won <span ref={countUpRef} /> LUNA
          </PrizeText>
        )}
      </CountUp>

      {status === WalletStatus.WALLET_NOT_CONNECTED && (
        <button
          className="spin-btn"
          onClick={() => connect(ConnectType.EXTENSION, 'station')}
          disabled={isTxLoading}
          style={{ fontSize: '2em' }}
        >
          Connect Terra Station
        </button>
      )}
      {status === WalletStatus.WALLET_CONNECTED && (
        <button className="spin-btn" onClick={doSpin} disabled={isTxLoading}>
          SPIN
        </button>
      )}
    </div>
  );
};

export default SlotMachineComponent;

async function pollTxInfo(
  network: NetworkInfo,
  txhash: string,
): Promise<TxInfo> {
  const until = Date.now() + 1000 * 60 * 60;
  const untilInterval = Date.now() + 1000 * 60;

  const lcd = new LCDClient({
    chainID: network.chainID,
    URL: network.lcd,
  });

  while (true) {
    let txInfo;

    try {
      txInfo = await lcd.tx.txInfo(txhash);
    } catch {}

    if (txInfo) {
      return txInfo;
    } else if (Date.now() < untilInterval) {
      await sleep(500);
    } else if (Date.now() < until) {
      await sleep(1000 * 10);
    } else {
      throw new Error(
        `Transaction queued. To verify the status, please check the transaction hash below.`,
      );
    }
  }
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
