// import { ReactComponent as Logo } from '../images/TerraswapLogo.svg';
// import Container from '../components/Container';
// import { Link } from "react-router-dom"
// import Sidebar from "./Sidebar"
// import styled from 'styled-components';

import Disconnect from './Disconnect';
import Help from './Help';
import HelpModal from './HelpModal';
import { useModal } from './Modal';
import { useAnalyticsEventTracker } from 'hooks';

import styles from './Header.module.scss';
import HeaderButton from './HeaderButton';

// const SidebarWrapper = styled.div`
//   position: relative;

//   width: 100%;
//   height: auto;
//   position: fixed;
//   padding-top: 50px;

//   @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
//     z-index: 4900;
//     padding: 0;
//   }
// `;

const Header = () => {
  //   const [isScrolled, setIsScrolled] = useState(false)
  //   useEffect(() => {
  //     let timerId: any
  //     const showConnectButtonAfter1Sec = () => {
  //       if (timerId) {
  //         clearTimeout(timerId)
  //       }

  //       timerId = setTimeout(() => {
  //         setIsScrolled(false)
  //       }, 875)
  //     }
  //     const handleWheel = () => {
  //       setIsScrolled(true)
  //       showConnectButtonAfter1Sec()
  //     }

  //     window.addEventListener("wheel", handleWheel)
  //     window.addEventListener("touchmove", handleWheel)
  //     return () => {
  //       window.removeEventListener("wheel", handleWheel)
  //       window.removeEventListener("touchmove", handleWheel)
  //     }
  //   }, [])

  //   useEffect(() => {
  //     if (isScrolled) {
  //       document.body.classList.add("scrolled", styles.scrolled)
  //       return
  //     }
  //     document.body.classList.remove("scrolled", styles.scrolled)
  //   }, [isScrolled])

  const gaEventTracker = useAnalyticsEventTracker();

  const helpModal = useModal();

  const helpBtnOnClick = () => {
    gaEventTracker('Help', 'Click', 'Help button click', 0);
    helpModal.open();
  };

  const discordBtnOnClick = () => {
    gaEventTracker('Discord', 'Click', 'Discord button click', 0);
    window.open('https://discord.gg/gXjNHuxjVq', '_blank');
  };

  return (
    <>
      <header className={styles.header} style={{ padding: '10px' }}>
        {/* <Container className={styles.container}> */}
        <div className={styles.container}>
          <section className={styles.wrapper}>
            {/* LOGO */}
            {/* <Link to="/">
              <Logo height={31.2} className={styles.logo} />
            </Link> */}
          </section>

          <section className={styles.support}>
            <div className={styles.connect}>
              <Disconnect />
              <Help onClick={helpBtnOnClick} />
              <HeaderButton text={'Discord'} onClick={discordBtnOnClick} />
            </div>
          </section>
        </div>
        {/* </Container> */}
      </header>

      {/* <SidebarWrapper>
        <Container className={styles.container}>
          <Sidebar />
        </Container>
      </SidebarWrapper> */}

      <HelpModal isOpen={helpModal.isOpen} handleClose={helpModal.close} />
    </>
  );
};

export default Header;
