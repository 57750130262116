import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from 'react-ga';
import { getChainOptions, WalletProvider } from '@terra-money/wallet-provider';

import Header from 'components/Header';
import SlotMachine from './components/SlotMachine/index';
import { ContractsProvider } from 'contexts/contracts';
import Network from 'layouts/Network';

import './index.scss';

const TRACKING_ID = 'UA-233463325-1';
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div>
      <Header />
      <SlotMachine />
    </div>
  );
}

const queryClient = new QueryClient();

getChainOptions().then((chainOptions) => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(
    <QueryClientProvider client={queryClient}>
      <WalletProvider {...chainOptions}>
        <Network>
          <ContractsProvider>
            <App />
          </ContractsProvider>
        </Network>
      </WalletProvider>
    </QueryClientProvider>,
  );
});
