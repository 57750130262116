import { useWallet } from '@terra-money/wallet-provider';
// import { FINDER } from "constants/constants"
// import terraswapNetworks from "constants/networks"
import TerraLunaBurnerNetwork from 'constants/networks';

const useNetwork = () => {
  const { network } = useWallet();
  // const getFinderUrl = (address: string, path: string = "account") =>
  //   `${FINDER}/${network.chainID}/${path}/${address}`

  return {
    ...network,
    ...TerraLunaBurnerNetwork[network.name],
    // getFinderUrl,
    // fcd: network.lcd?.replace('lcd', 'fcd'),
    //
  };
};

export default useNetwork;
