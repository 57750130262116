export enum NetworkKey {
  MAINNET = 'mainnet',
  TESTNET = 'testnet',
}

interface TerraLunaBurnerNetwork {
  service: string;
  app: string;
  fee: {
    gasPrice: string;
    amount: string;
    gas: string;
  };
}

// export const AVAILABLE_CHAIN_ID_LIST = ['phoenix-1', 'pisco-1'];
export const AVAILABLE_CHAIN_ID_LIST = ['columbus-5', 'localterra'];

const terraswapNetworks: Record<string, TerraLunaBurnerNetwork> = {
  mainnet: {
    service:
      process.env.REACT_APP_MAINNET_SERVICE_URL || 'https://api.terraswap.io/',
    app: 'terra13ehuhysn5mqjeaheeuew2gjs785f6k7jm8vfsqg3jhtpkwppcmzqcu7chk',
    fee: { gasPrice: '0.15', amount: '1518', gas: '500000' },
  },
  testnet: {
    service:
      process.env.REACT_APP_TESTNET_SERVICE_URL ||
      'https://api-pisco.terraswap.io/',
    app: 'terra1xp6xe6uwqrspumrkazdg90876ns4h78yw03vfxghhcy03yexcrcsdaqvc8',
    fee: { gasPrice: '0.15', amount: '1518', gas: '500000' },
  },
};

export default terraswapNetworks;
