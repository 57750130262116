export enum QUERY_KEYS {
  GET_STATE = 'GET_STATE',
}

export enum TX_KEYS {
  // INCREMENT = "INCREMENT",
  // DECREMENT = "DECREMENT",
  PLAY = 'PLAY',
}

export const TX_REFETCH_MAP = {
  // [TX_KEYS.INCREMENT]: [QUERY_KEYS.GET_COUNT],
  // [TX_KEYS.DECREMENT]: [QUERY_KEYS.GET_COUNT],
  [TX_KEYS.PLAY]: [QUERY_KEYS.GET_STATE],
} as const;
