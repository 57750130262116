const Help = (props: { onClick: () => void }) => {
  return (
    <div>
      {
        <button
          className="spin-btn"
          style={{
            width: '100px',
            height: '30px',
            fontSize: '1em',
            textAlign: 'right',
          }}
          onClick={() => props.onClick()}
        >
          Help
        </button>
      }
    </div>
  );
};

export default Help;
