import { useCallback } from 'react';
import ReactGA from 'react-ga';

const useAnalyticsEventTracker = () => {
  const eventTracker = useCallback(
    (category: string, action: string, label: string, value: number) => {
      ReactGA.event({ category, action, label, value });
    },
    [],
  );

  return eventTracker;
};

export default useAnalyticsEventTracker;
