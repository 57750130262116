import { LCDClient } from '@terra-money/terra.js';
import {
  useWallet,
  useLCDClient,
  useConnectedWallet,
  WalletStatus,
} from '@terra-money/wallet-provider';
import { useContracts } from 'contexts/contracts';
import { QUERY_KEYS } from 'env';
import { useAddress } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import styled from 'styled-components';
import styles from './Settings.module.scss';

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
    display: block;
    height: 48px;
    padding: 0;
  }

  &,
  & * {
    box-sizing: border-box;
  }
`;

// https://codepen.io/mandymichael/pen/xpLNeV
const BurntText = styled.div`
  background: linear-gradient(
    to bottom,
    #cfc09f 22%,
    #634f2c 24%,
    #cfc09f 26%,
    #cfc09f 27%,
    #ffecb3 40%,
    #3a2c0f 78%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-family: 'Playfair Display', serif;
  position: relative;
  text-transform: uppercase;
  font-size: 3em;
  margin: 0;
  font-weight: 400;
  text-align: center;

  &:after {
    background: none;
    content: attr(data-heading);
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    text-shadow: -1px 0 1px #c6bb9f, 0 1px 1px #c6bb9f,
      5px 5px 10px rgba(0, 0, 0, 0.4), -5px -5px 10px rgba(0, 0, 0, 0.4);
  }
`;

const TitleText = styled.div`
  background: linear-gradient(
    to bottom,
    #cfc09f 22%,
    #634f2c 24%,
    #cfc09f 26%,
    #cfc09f 27%,
    #ffecb3 40%,
    #3a2c0f 78%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-family: 'Playfair Display', serif;
  position: relative;
  text-transform: uppercase;
  font-size: 5em;
  margin: 0;
  font-weight: 700;
  text-align: center;

  &:after {
    background: none;
    content: attr(data-heading);
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    text-shadow: -1px 0 1px #c6bb9f, 0 1px 1px #c6bb9f,
      5px 5px 10px rgba(0, 0, 0, 0.8), -5px -5px 10px rgba(0, 0, 0, 0.4);
  }
`;

const SummaryWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  background-color: #ffffff24;
  border-radius: 15px;
  overflow: hidden;
  // @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
  //   display: block;
  //   height: 48px;
  //   padding: 0;
  // }

  @media screen and {
    display: block;
    height: 48px;
    padding: 0;
  }

  &,
  & * {
    box-sizing: border-box;
  }
`;

const SummaryList = styled.div`
  position: relative;
  display: block;

  padding: 13px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: top 0.2s ease-in-out;
  font-size: 0;

  // @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
  //   display: block;
  //   height: 48px;
  //   padding: 0;
  // }

  @media screen and (max-width: 1000px) {
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;

const SummaryItem = styled.div.attrs()`
  width: auto;
  height: auto;
  display: inline-block;
  position: relative;

  // @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
  //   display: block;
  //   padding: 13px 13px;
  // }

  @media screen and (max-width: 1000px) {
    display: block;
    padding: 13px 13px;
  }

  & label {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    display: inline-block;
    margin-right: 13px;
  }

  & span {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  & .variation {
    color: ${({ variation }) => ((variation || 0) > 0 ? '#08d9b9' : '#f15e7e')};
  }
`;

const Summary = ({ betsize, onChangeBetsize }) => {
  const { status } = useWallet();

  // console.log(status);

  return (
    <Wrapper>
      <TitleText>TERRA LUNA BURNER</TitleText>
      {status === WalletStatus.WALLET_CONNECTED && (
        <Info betsize={betsize} onChangeBetsize={onChangeBetsize} />
      )}
    </Wrapper>
  );
};

export default Summary;

const Info = ({ betsize, onChangeBetsize }) => {
  const connectedWallet = useConnectedWallet();
  const terra = useLCDClient({
    chainID: connectedWallet.network.chainID,
    URL: connectedWallet.network.lcd,
  });
  const address = useAddress();

  // Contracts
  const { slotsAddr } = useContracts();

  // Query contract: getState
  const { data } = useQuery(
    [QUERY_KEYS.GET_STATE, connectedWallet?.network, slotsAddr],
    queryGetStateFn,
    {
      refetchInterval: 1000 * 5,
      keepPreviousData: true,
    },
  );

  // Query wallet native balance
  const [balanceUluna, setBalanceUluna] = useState();

  const getBalance = useCallback(async () => {
    const [balance] = await terra.bank.balance(address);
    const ulunaBalance = balance
      .toData()
      .filter((x) => x.denom === 'uluna')[0].amount;
    setBalanceUluna(ulunaBalance);
  }, [address, terra.bank]);

  useEffect(() => {
    getBalance();
    const getBalanceTimer = setInterval(getBalance, 1000 * 5);

    return () => {
      clearTimeout(getBalanceTimer);
    };
  }, [getBalance]);

  const formatCurrency = (num) => {
    return num.toLocaleString('en-US', { maximumFractionDigits: 6 });
  };

  return (
    <>
      <SummaryWrapper>
        <SummaryList>
          <SummaryItem>
            <label>Balance:</label>{' '}
            <span>
              {balanceUluna ? formatCurrency(balanceUluna / 10 ** 6) : 0} LUNA
            </span>
          </SummaryItem>
          <SummaryItem>
            <label>Jackpot:</label>{' '}
            <span>{data ? formatCurrency(data.pot / 10 ** 6) : 0} LUNA</span>
          </SummaryItem>
          <SummaryItem>
            <label>Bet:</label>
            <span className={styles['radio-group']}>
              <label className={styles['radio-group__item']}>
                <span>
                  <input
                    type="number"
                    min={1}
                    step={1}
                    value={betsize.toString()}
                    onChange={onChangeBetsize}
                  />
                </span>
                LUNA
              </label>
            </span>
          </SummaryItem>
        </SummaryList>
      </SummaryWrapper>
      <BurntText>
        Burnt: {data && formatCurrency(data.burnt / 10 ** 6)} LUNA
      </BurntText>
    </>
  );
};

function createQueryFn(fn) {
  return ({ queryKey: [, ...args] }) => {
    return fn(...args);
  };
}

export const queryGetStateFn = createQueryFn(async (network, slotsAddr) => {
  if (!network || !slotsAddr) {
    return null;
  }

  const lcd = new LCDClient({
    chainID: network.chainID,
    URL: network.lcd,
  });

  // const r = lcd.wasm.contractQuery(slotsAddr, { get_state: {} });
  // console.log(await r);
  // return r;

  return lcd.wasm.contractQuery(slotsAddr, { get_state: {} });
});
